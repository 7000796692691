<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Alert :message="requestError" type="alert-danger" v-if="requestError" />
    <!-- Modal -->
    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Delete Content</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Are you sure you want to do this ?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              @click="deleteContent"
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal end -->
    <div class="row">
      <div class="col-md-12 content_margin_bottom">
        <div class="row">
          <div class="col-md-12 mb-4">
            <h4 class="card-title my-3 float-right">
              <router-link
                :to="`/entity/${entityId}/courses`"
                class="btn btn-success btn-sm"
                >Courses</router-link
              >
            </h4>
          </div>
        </div>
        <div class="table-wrapper">
          <table class="table table-borderless">
            <thead>
              <tr>
                <td scope="col"><b>Test Title</b></td>
                <td scope="col">{{ content.unit_name }}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><b>Pass Score</b></td>
                <td>{{ content.pass_score }}</td>
              </tr>
              <tr>
                <td><b>Question Duration</b></td>
                <td>{{ content.duration_in_secs }}</td>
              </tr>
              <tr>
                <td><b>Test Description</b></td>
                <td>{{ content.description }}</td>
              </tr>
              <tr>
                <td><b>Maximum Number of Attempts</b></td>
                <td>{{ content.max_num_attempts }}</td>
              </tr>
              <tr class="d-flex justify-content-center">
                <td><b>Questions</b></td>
                <td></td>
              </tr>
              <tr
                v-for="(question, index) in content.questions"
                :key="index"
                class="d-flex flex-column"
              >
                <div class="d-flex justify-content-between">
                  <td>
                    <b> Question</b>
                  </td>
                  <td>
                    {{ question.ques }}
                  </td>
                </div>
                <div class="d-flex justify-content-between">
                  <td><b>Weight</b></td>
                  <td>{{ question.weight }}</td>
                </div>
                <div
                  v-for="answers in question.answers"
                  id="border"
                  :key="answers.id"
                >
                  <div class="d-flex justify-content-between">
                    <td><b>Choice</b></td>
                    <td>{{ answers.choice }}</td>
                  </div>
                  <div class="d-flex justify-content-between">
                    <td><b>Correct</b></td>
                    <td>{{ answers.correct }}</td>
                  </div>
                </div>
              </tr>
            </tbody>
          </table>
          <div class=" float-right">
            <router-link
              :to="
                `/entity/${$route.params.id}/course/${$route.params.courseId}/test/${$route.params.contentId}/edit`
              "
              class="btn btn-success btn-sm"
            >
              Edit Test
            </router-link>
            <button
              data-toggle="modal"
              data-target="#deleteModal"
              class="btn btn-danger btn-sm  m-3"
            >
              Delete Test
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layout/index.vue";
import PageHeader from "@/components/page-header";
import Alert from "@/components/Alert.vue";
import appConfig from "@/app.config";
import store from "@/state/store";

export default {
  page: {
    title: "Test",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Alert,
    PageHeader,
    Layout,
  },
  data() {
    return {
      // data table meta
      entityId: this.$route.params.id,
      contentId: this.$route.params.contentId,

      //end of table meta
      loading: false,
      id: null,
      requestError: null,
      success: null,
      error: null,
      key: null,
      title: "Course Test",
      items: [
        {
          text: "Entity Admin",
        },
        {
          text: "Test",
          active: true,
        },
      ],
      content: {},
    };
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteContent() {
      store
        .dispatch("contents/deleteContent", this.contentId)
        .then((response) => {
          if (response) {
            this.fetchData();
            this.toast({
              message: "Content Deleted.",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data == undefined) {
            this.toast({
              message:
                "Network error. Check your network connection and try again.",
              type: "error",
            });
            this.$router.push(`/entity/${this.$route.params.id}/courses`);
          }
        });
    },
  },
  created() {
    store
      .dispatch("contents/viewContent", this.contentId)
      .then((res) => {
        console.log(res.data, "content");
        const test = res.data;
        this.content = test;
      })
      .catch((err) => {
        if (err?.response?.data === undefined)
          this.requestError = "An error occured";
      });
  },
};
</script>
<style scoped>
#border {
  border-bottom: 1px solid #00000042;
}
</style>
